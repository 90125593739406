import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { MenuItem, TextField, InputAdornment } from "@mui/material";
import { NewActionButton, DeleteActionButton } from "../../../widgets/actionIcons"
import { setSelectedFarm, reloadFarms } from "../../redux/reducer";
import { selectFarm, selectFarms } from "../../redux/selectors";
import { useFarmApi } from "../../hooks";
import { usePrompt } from "../../../hooks/modal";
import { NOT_EMPTY_VALIDATOR, NAME_VALIDATOR } from "../../../hooks/validation";

export const FarmSelector = ({ onChange, canCreate }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const prompt = usePrompt();
    const farms = useSelector(selectFarms);
    const farm = useSelector(selectFarm);
    const farmApi = useFarmApi();
    const singleFarm = farms?.length < 2;

    if (!farms) return null;

    function handleFarmCreate() {
        const validator = [
            NOT_EMPTY_VALIDATOR,
            NAME_VALIDATOR(t("shared:validation-length", 2, 32))
        ];
        prompt(
            t("shared:farms.title-confirm-create-farm"),
            (name) => {
                farmApi.createFarm({ name }, (newFarm) => {
                    dispatch(reloadFarms());
                    dispatch(setSelectedFarm(newFarm.id));
                });
            },
            { label: t("shared:farms.label-farm-name"), action: t("shared:btn-create"), validator }
        )
    }

    function handleFarmDelete() {
        const validator = (v) => (v === t("shared:label-confirm-delete")) ? null : "";
        prompt(
            t("shared:farms.title-confirm-delete-farm"),
            () => {
                farmApi.deleteFarm(farm.id, () => dispatch(reloadFarms()));
            },
            { label: t("shared:farms.text-confirm-delete-farm"), action: t("shared:btn-delete"), validator }
        )
    }

    function handleFarmChange(e) {
        const farmId = e.target.value;
        dispatch(setSelectedFarm(farmId));
        onChange(farmId);
    }

    return (<TextField
        value={singleFarm ? (farm?.name || "-") : (farm?.id || "-")}
        helperText={t("shared:farms.label-num-fields", { count: farm?.fields?.length || "-" })}
        onChange={handleFarmChange}
        select={!singleFarm}
        margin="dense"
        variant="standard"
        sx={{ width: "30ch" }}
        data-cy="farm-selector"
        slotProps={{
            input: {
                readOnly: singleFarm && !canCreate,
                endAdornment: canCreate
                    ? <InputAdornment position="end">
                        {(!singleFarm || null) && <DeleteActionButton onClick={handleFarmDelete} />}
                        <NewActionButton onClick={handleFarmCreate} style={{ marginRight: "15px" }} />
                    </InputAdornment>
                    : undefined
            }
        }}
    >
        {farms.map((f) => <MenuItem key={f.id} value={f.id}>{f.name}</MenuItem>)}
    </TextField>);
};
