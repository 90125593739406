import { CssBaseline, AppBar, Toolbar, IconButton, Typography, Box } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import logo from "../assets/logo.svg";
import { useAuthContext } from "../authContext";
import { isDesktop } from "../utils/layout";

export const AppContainer = ({ width, noMaxWidth, children }) => (<Box>
    <CssBaseline />
    <Box sx={isDesktop()
        ? {
            margin: "0 auto",
            minWidth: width || "1200px",
            maxWidth: noMaxWidth ? undefined : (width || "1200px"),
            overflow: "auto"
        }
        : {
            width: "100vw"
        }}>
        {children}
    </Box>
</Box>);

export const AppToolBar = ({ title, onDrawer, children }) => {
    const { profile } = useAuthContext();

    return (<AppBar position="static" sx={{ backgroundColor: profile?.impersonated_by ? "#FF0000" : "#3F8361", color: "white" }}>
        <Toolbar variant="dense">
            <IconButton
                edge="start"
                onClick={onDrawer}
                data-cy="app-menu"
            >
                <MenuIcon />
            </IconButton>
            <a href="/" style={{ display: "flex", alignItems: "center", textDecoration: "none", flexGrow: 1, color: "white" }}>
                <img src={logo} style={{ width: 50 }} />
                <Typography variant="h6" component="div">{title}</Typography>
            </a>
            {children}
        </Toolbar>
    </AppBar>);
};
