import { useState, useEffect } from "react";
import { Provider, useSelector, useDispatch } from "react-redux";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { IconButton, Alert, Snackbar, Drawer, List, ListItem, ListItemText, ListItemIcon, Divider, Switch } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowRightIcon from "@mui/icons-material/NavigateNext";
import LanguageIcon from "@mui/icons-material/Language";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import LogoutIcon from "@mui/icons-material/Logout";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import AssessmentIcon from "@mui/icons-material/Assessment";
import OndemandVideoOutlinedIcon from "@mui/icons-material/OndemandVideoOutlined";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { FarmSelector } from "@agricircle/shared/farms/components";
import { CropFilter, ModeOptions } from "./components/widgets";
import Product from "./components/Product";
import Home from "./components/Home";
import AssessmentModal from "./components/AssessmentModal";
import SupportModal from "./components/SupportModal";
import { useAuthContext, LoadingProvider } from "@agricircle/shared";
import { selectNextMessage, popMessage } from "@agricircle/shared/redux";
import { useAsyncApi, apiUrl, deserializeJsonapiEntity, ModalProvider, useModal } from "@agricircle/shared/hooks";
import { AppContainer, AppToolBar, LanguageSelector } from "@agricircle/shared/widgets";
import { PollingProvider } from "./PollingContext";
import { loadFarmIQ } from "./hooks/farmiq";
import { store } from "./redux/store";
import { selectMode } from "./redux/farmiq/selectors";
import { setMode } from "./redux/farmiq";


const DRAWER_WIDTH = 350;


const FarmIQMenu = ({ onClose }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { openModal } = useModal();
    const mode = useSelector(selectMode);
    const { profile } = useAuthContext();

    function handleLogout() {
        if (profile.impersonated_by) {
            const rootDomain = (new URL(apiUrl)).hostname;
            window.location.href = `${apiUrl}/auth/impersonate/stop?after_logout=https://${rootDomain}/tools/admin`;
        } else {
            window.location.href = `${apiUrl}/auth/logout?after_logout=https://${window.location.host}/`;
        }
    }

    function handleToggleMode() {
        onClose();
        dispatch(setMode(mode === "edit" ? "work" : "edit"));
    }

    function handleAssessment() {
        onClose();
        openModal(t("title-prepare-assessment"), <AssessmentModal />);
    }

    function handleSupport() {
        onClose();
        openModal(t("title-support"), <SupportModal />);
    }

    return (<List dense>
        <ListItem secondaryAction={<IconButton data-cy="menu-close" onClick={onClose}><CloseIcon /></IconButton>}>
            <ListItemText primary={<b>MyFarmIQ</b>} />
        </ListItem>
        <ListItem>
            <ListItemIcon><LocationOnIcon /></ListItemIcon>
            <FarmSelector onChange={onClose} canCreate={mode === "edit"} />
        </ListItem>
        <ListItem>
            <ListItemIcon>{mode === "edit" ? <ModeEditIcon /> : <MapOutlinedIcon />}</ListItemIcon>
            <ListItemText primary={t("shared:title-edit-mode")} />
            <Switch
                data-cy="menu-mode"
                edge="end"
                onChange={handleToggleMode}
                checked={mode === "edit"}
            />
        </ListItem>
        {(mode === "work" || null) && (<ListItem secondaryAction={<IconButton data-cy="menu-assess" onClick={handleAssessment}><ArrowRightIcon /></IconButton>}>
            <ListItemIcon><AssessmentIcon /></ListItemIcon>
            <ListItemText primary={t("title-prepare-assessment")} />
        </ListItem>)}
        <Divider sx={{ margin: "10px 0 10px 0" }} />
        <ListItem secondaryAction={<IconButton data-cy="menu-product" href="/"><ArrowRightIcon /></IconButton>}>
            <ListItemIcon><OndemandVideoOutlinedIcon /></ListItemIcon>
            <ListItemText primary={t("title-guides")} />
        </ListItem>
        <ListItem secondaryAction={<IconButton data-cy="menu-support" onClick={handleSupport}><ArrowRightIcon /></IconButton>}>
            <ListItemIcon><SupportAgentIcon /></ListItemIcon>
            <ListItemText primary={t("title-support")} />
        </ListItem>
        <Divider sx={{ margin: "10px 0 10px 0" }} />
        <ListItem secondaryAction={<IconButton data-cy="menu-logout" onClick={handleLogout}><ArrowRightIcon /></IconButton>}>
            <ListItemIcon><LogoutIcon /></ListItemIcon>
            <ListItemText primary={t(profile.impersonated_by ? "shared:title-stop-impersonation" : "shared:title-logout")} secondary={profile.name} />
        </ListItem>
        <ListItem>
            <ListItemIcon><LanguageIcon /></ListItemIcon>
            <LanguageSelector />
        </ListItem>
    </List>);
};

const FarmIQAppBar = () => {
    const [drawerOpen, setDrawerOpen] = useState(false);

    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
    };

    return (<div>
        <AppToolBar title="MyFarmIQ" onDrawer={handleDrawerToggle}>
            <CropFilter />
            <ModeOptions />
        </AppToolBar >
        <Drawer
            container={window.document.body}
            variant="temporary"
            open={drawerOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
                keepMounted: true, // Better open performance on mobile.
            }}
            sx={{ "& .MuiDrawer-paper": { boxSizing: "border-box", width: { xs: "100%", sm: DRAWER_WIDTH } } }}
        >
            <FarmIQMenu onClose={handleDrawerToggle} />
        </Drawer>
    </div >);
};

const App = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const sendRequest = useAsyncApi();
    const { message, isErrorMessage } = useSelector(selectNextMessage);
    const [delayed, setDelayed] = useState(true);
    const { profile, setProfile } = useAuthContext();

    function onCloseMessage() {
        dispatch(popMessage());
    }

    useEffect(() => {
        if (profile) return;
        const loadProfile = async () => {
            const result = await sendRequest("GET", "users/myself", null, null, true);
            if (result.status > 300)
                setProfile({ status: result.status });
            else
                setProfile(deserializeJsonapiEntity(result.data));
        };

        loadProfile();
        const timeout = setTimeout(() => setDelayed(false), 500);
        return () => clearTimeout(timeout);
    }, []);

    loadFarmIQ();

    if (profile == null) return delayed ? null : t("shared:loading");
    const unauthorized = profile.id && !profile.organization?.roles?.includes("myfarmiq");

    return (<div>
        <ModalProvider>
            <BrowserRouter basename="/">
                <AppContainer noMaxWidth>
                    <Snackbar open={Boolean(message)} onClose={onCloseMessage} autoHideDuration={4000}>
                        <Alert onClose={onCloseMessage} severity={isErrorMessage ? "error" : "success"} style={{ width: "100%" }}>{message}</Alert>
                    </Snackbar>
                    <div style={{ display: "flex" }}>
                        <Routes>
                            <Route path="/" element={<Product />} />
                            <Route path="*" element={profile.id && !unauthorized
                                ? <div style={{ width: "100%" }}><FarmIQAppBar /><Home /></div>
                                : <Navigate to="/" replace />
                            } />
                        </Routes>
                    </div>
                </AppContainer>
            </BrowserRouter>
        </ModalProvider>
    </div>);
};

export default () => (<Provider store={store}>
    <LoadingProvider>
        <PollingProvider>
            <App />
        </PollingProvider>
    </LoadingProvider>
</Provider>);
