import { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Dialog, DialogContent, DialogActions, Stack, TextField, IconButton, Button, List, ListItem, ListItemText, ListItemButton } from "@mui/material";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { FarmEditor } from "@agricircle/shared/farms/components";
import { useModal, useValidation, NOT_EMPTY_VALIDATOR, RANGE_VALIDATOR } from "@agricircle/shared/hooks";
import { selectFarm } from "@agricircle/shared/farms/redux";
import { today } from "@agricircle/shared/utils";
import { selectFilterCrop } from "../redux/farmiq/selectors";
import { useCropUtils } from "../hooks/cropUtils";
import { useCropHistoryApi } from "../hooks/cropHistoryApi";
import { DateField, CropSelector } from "./widgets";


export default () => {
    const { openModal } = useModal();
    const farm = useSelector(selectFarm);
    const filterCrop = useSelector(selectFilterCrop);

    const fields = useMemo(() => {
        if (!farm?.fields?.length || !filterCrop) return farm?.fields || null;
        return farm.fields.filter(f => f.crop?.id === filterCrop);
    }, [farm, filterCrop]);

    if (!fields) return;

    function handleFieldClick(fieldId) {
        if (!fieldId) return;
        openModal(
            null,
            <FieldEditModal />,
            null,
            { fieldId }
        );
    }

    return (<FarmEditor fieldInfo={(fieldId) => <FieldInfo fieldId={fieldId} />} />);
};


const FieldInfo = ({ fieldId }) => {
    const { t } = useTranslation();
    const cropUtils = useCropUtils();
    const farm = useSelector(selectFarm);
    const filterCrop = useSelector(selectFilterCrop);
    const cropHistoryApi = useCropHistoryApi();
    const [cropHistory, setCropHistory] = useState(null);
    const [editedCropEntry, setEditedCropEntry] = useState(null);

    const field = useMemo(() => {
        if (!farm?.fields?.length) return null;
        return farm.fields.find(f => (!filterCrop || f.crop?.id === filterCrop) && f.id === fieldId);
    }, [fieldId, filterCrop]);


    useEffect(() => {
        if (cropHistory == null)
            cropHistoryApi.list(fieldId, setCropHistory);
    }, [cropHistory == null]);

    if (!field) return null;

    function handleSaveCropEntry(entry) {
        const isNew = !editedCropEntry.start_date;

        const onDone = () => {
            setCropHistory(null);
            setEditedCropEntry(null);
        };
        if (isNew)
            cropHistoryApi.add(fieldId, entry, onDone);
        else
            cropHistoryApi.patch(fieldId, editedEntry.start_date, entry, onDone);
    }

    function handleDeleteCropEntry(entry) {
        if (confirm(t("message-confirm-history-delete")))
            cropHistoryApi.delete(fieldId, entry.start_date, () => setCropHistory(null));
    }

    return (<Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
            <div>{t(cropHistory?.length ? "label-crops" : "label-no-crops")}</div>
            <Button size="small" onClick={() => setEditedCropEntry({})}>
                {t("btn-new-crop")}
            </Button>
        </Stack>
        {(editedCropEntry || null) && (<CropEntryDialog
            entry={editedCropEntry}
            onClose={() => setEditedCropEntry(null)}
            onSave={handleSaveCropEntry}
        />)}
        {(cropHistory?.length || null) && (<List dense sx={{ margin: "0 !important", padding: "0 !important" }}>
            {cropHistory.map((h, i) => (<ListItem
                key={`history${i}`}
                secondaryAction={<IconButton onClick={() => handleDeleteCropEntry(h)}>
                    <DeleteForeverOutlinedIcon />
                </IconButton>}
            >
                <ListItemButton onClick={() => setEditedCropEntry(h)}>
                    <ListItemText
                        primary={cropUtils.cropName(h.crop_id)}
                        secondary={`${h.start_date} - ${h.end_date || i && cropHistory[i - 1].start_date || ""}`}
                    />
                </ListItemButton>
            </ListItem>))}
        </List>)}
    </Stack>);
};


const CropEntryDialog = ({ entry: initialEntry, onClose, onSave }) => {
    const { t } = useTranslation();
    const VALIDATION_OPTIONS = {
        crop_id: { validator: NOT_EMPTY_VALIDATOR },
        variety: {},
        secondary_crop_id: {},
        start_date: { validator: [NOT_EMPTY_VALIDATOR, RANGE_VALIDATOR("2000-01-01", today(), t("shared:validation-range", { min: "2000-01-01", max: today() }))] },
        end_date: { validateUndef: true, validator: (v) => (!v || v >= "2000-01-01" && v <= today()) ? null : t("shared:validation-range", { min: t("label-start-date"), max: today() }) },
        residue_management: {},
    };
    const validation = useValidation(VALIDATION_OPTIONS);
    const [entry, setEntry] = useState(initialEntry.start_date ? initialEntry : { crop_id: null, start_date: null });
    const newEntry = !initialEntry?.start_date;

    useEffect(() => {
        validation.init(entry);
    }, []);

    function handlePropChange(prop, v) {
        const value = validation.validate(prop, v);
        if (value === undefined) return;
        setEntry({ ...entry, [prop]: value });
    }

    function handleValueChange(e) {
        handlePropChange(e.target.name, e.target.value);
    }

    function handleSave() {
        const payload = validation.modifiedProperties(entry);
        if (payload.crop_id == "")
            payload.crop_id = null;
        if (payload.secondary_crop_id == "")
            payload.secondary_crop_id = null;
        onSave(payload);
    }

    return (<Dialog open={true} onClose={onClose} maxWidth="xs" fullWidth>
        <DialogContent sx={{ display: "flex", flexDirection: "column" }}>
            <DateField
                name="start_date"
                label={t("label-start-date")}
                value={entry.start_date || ""}
                onChange={handleValueChange}
                required
                error={!validation.isValid("start_date")}
                helperText={validation.error("start_date")}
                readOnly={!newEntry}
            />
            <DateField
                name="end_date"
                label={t("label-end-date")}
                value={entry.end_date || ""}
                onChange={handleValueChange}
                error={!validation.isValid("end_date")}
                helperText={validation.error("end_date")}
            />
            <CropSelector
                value={entry.crop_id || ""}
                label={t("label-crop")}
                onChange={(c) => handlePropChange("crop_id", c)}
                required
                error={!validation.isValid("crop_id")}
                helperText={validation.error("crop_id")}
                showFavorites
                fullWidth
            />
            <TextField
                name="variety"
                label={t("label-crop-variety")}
                value={entry.variety || ""}
                onChange={handleValueChange}
                error={!validation.isValid("variety")}
                helperText={validation.error("variety")}
                margin="dense"
                variant="standard"
                fullWidth
                autoComplete="off"
            />
            <CropSelector
                value={entry.secondary_crop_id || ""}
                label={t("label-secondary-crop")} onChange={(c) => handlePropChange("secondary_crop_id", c)}
                error={!validation.isValid("secondary_crop_id")}
                helperText={validation.error("secondary_crop_id")}
                showFavorites
                fullWidth
            />
        </DialogContent>

        <DialogActions>
            <Button
                variant="text"
                data-cy="button-cancel"
                onClick={onClose}
                size="small"
                sx={{ minWidth: "15ch" }}
            >
                {t("shared:btn-cancel")}
            </Button>
            <Button
                variant="text"
                data-cy="button-ok"
                size="small"
                onClick={handleSave}
                disabled={!validation.isValid() || !validation.isModified()}
                sx={{ minWidth: "15ch" }}
            >
                {t("shared:btn-ok")}
            </Button>
        </DialogActions>
    </Dialog>);
};

