import { produce } from "immer";

const savedSelectedFarmId = localStorage.getItem("agricircle_farm");
const savedMapLayer = localStorage.getItem("agricircle_map_layer");

const initialState = {
    farms: null,
    selectedFarm: (savedSelectedFarmId && savedSelectedFarmId !== "null") ? { id: savedSelectedFarmId } : null,
    selectedFarmImports: undefined,
    mapPosition: null,
    mapLayer: (savedMapLayer && savedMapLayer != "null") ? savedMapLayer : null
};

export const farmsReducer = (state = initialState, action) => produce(state, draft => {
    switch (action.type) {
        case "SET_FARMS": {
            const farms = action.payload.farms;
            if (farms) {
                if (farms.length) {
                    const selectedFarmId = farms.find(farm => farm.id === state.selectedFarm?.id)?.id || null;
                    if (!selectedFarmId) {
                        localStorage.setItem("agricircle_farm", farms[0].id);
                    }
                    draft.selectedFarm = { id: selectedFarmId, ts: 0 };
                } else {
                    draft.selectedFarm = null;
                    localStorage.setItem("agricircle_farm", null);
                }
                draft.farms = farms;
            } else {
                draft.farms = null;
                draft.selectedFarm = state.selectedFarm ? { id: state.selectedFarm.id, ts: 0 } : null;
            }
            draft.selectedFarmImports = undefined;
            break;
        }
        case "SET_SELECTED_FARM": {
            const selectedFarm = state.farms ? state.farms.find(farm => farm.id === action.payload.farmId) || null : { id: action.payload.farmId };
            draft.selectedFarm = selectedFarm ? { ...selectedFarm, fields: action.payload.fields, ts: Date.now() } : null;
            draft.selectedFarmImports = undefined;
            localStorage.setItem("agricircle_farm", action.payload.farmId);
            break;
        }
        case "UPDATE_FIELD":
            if (state.selectedFarm?.fields) {
                const updatedFields = state.selectedFarm.fields.map(field => (field.id === action.payload.field.id) ? action.payload.field : field);
                updatedFields.sort((f1, f2) => ((f1.name < f2.name) ? -1 : ((f1.name > f2.name) ? 1 : 0)));
                draft.selectedFarm = { ...state.selectedFarm, fields: updatedFields, ts: Date.now() };
            }
            break;
        case "DELETE_FIELD": {
            if (state.selectedFarm?.fields) {
                const remainingFields = state.selectedFarm.fields.filter(field => field.id !== action.payload.fieldId);
                draft.selectedFarm = { ...state.selectedFarm, fields: remainingFields, ts: Date.now() };
            }
            break;
        }
        case "SET_SELECTED_FARM_IMPORTS":
            if (!action.payload.farmImports)
                draft.selectedFarmImports = action.payload.farmImports;
            else
                draft.selectedFarmImports = { ...action.payload.farmImports, ts: Date.now() };
            break;
        case "SET_MAP_POSITION":
            draft.mapPosition = action.payload.position;
            break;
        case "SET_MAP_LAYER":
            draft.mapLayer = action.payload.layer;
            localStorage.setItem("agricircle_map_layer", action.payload.layer)
            break;
    }
    return draft;
});

export const reloadFarms = () => ({ type: "SET_FARMS", payload: { farms: null } });

export const setFarms = (farms) => ({ type: "SET_FARMS", payload: { farms } });

export const setSelectedFarm = (farmId, fields) => ({ type: "SET_SELECTED_FARM", payload: { farmId, fields } });

export const updateField = (field) => ({ type: "UPDATE_FIELD", payload: { field } });

export const deleteField = (fieldId) => ({ type: "DELETE_FIELD", payload: { fieldId } });

export const setSelectedFarmImports = (farmImports) => ({ type: "SET_SELECTED_FARM_IMPORTS", payload: { farmImports } });

export const setMapPosition = (position) => ({
    type: "SET_MAP_POSITION",
    payload: {
        position: position && {
            zoom: position.zoom,
            center: { lat: position.center.lat, lng: position.center.lng }
        }
    }
});

export const setMapLayer = (layer) => ({ type: "SET_MAP_LAYER", payload: { layer } });
