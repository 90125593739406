import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useAsyncApi, deserializeJsonapiEntity, deserializeJsonapiEntities, serializeToJsonapiEntity } from "../../hooks/asyncApi";
import { displayInfoMessage, displayErrorMessage } from "../../redux/messages";
import { setFarms, setSelectedFarm, setSelectedFarmImports } from "../redux/reducer";
import { selectFarms, selectFarm, selectImportStatus } from "../redux/selectors";


export const useFarmApi = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const sendRequest = useAsyncApi();

    ////////////////// List fields

    const listFields = async (farmId, options, callback) => {
        const attributes = options?.extraAttributes ? `,${options.extraAttributes.join(",")}` : "";
        const result = await sendRequest("GET", `fields?filter[farm]=${farmId}&fields[fields]=id,name,area,center_point,boundaries${attributes},farm&sort=name`);
        if (result.isError)
            dispatch(displayErrorMessage(t("shared:farms.errors.list-fields"), result.data));
        else
            callback && callback(deserializeJsonapiEntities(result.data));
        return result;
    };

    ////////////////// Create farm
    const createFarm = async (attributes, callback) => {
        const payload = serializeToJsonapiEntity(null, "farms", attributes);
        const result = await sendRequest("POST", "farms", payload);
        if (result.isError) {
            dispatch(displayErrorMessage(t("shared:farms.errors.create-farm"), result.data));
        } else {
            callback && callback(deserializeJsonapiEntity(result.data));
        }
        return result;
    };

    ////////////////// Delete farm
    const deleteFarm = async (farmId, callback) => {
        const result = await sendRequest("DELETE", `farms/${farmId}`);
        if (result.isError) {
            dispatch(displayErrorMessage(t("shared:farms.errors.delete-farm"), result.data));
        } else {
            callback && callback();
        }
        return result;
    };

    ////////////////// Update field
    const patchField = async (fieldId, attributes, callback) => {
        const payload = serializeToJsonapiEntity(fieldId, "fields", attributes);
        const result = await sendRequest("PATCH", `fields/${fieldId}`, payload);
        if (result.isError) {
            dispatch(displayErrorMessage(t("shared:farms.errors.update-field"), result.data));
        } else {
            dispatch(displayInfoMessage(t("shared:farms.message-field-updated")));
            callback && callback(deserializeJsonapiEntity(result.data));
        }
        return result;
    };

    ////////////////// Delete field
    const deleteField = async (fieldId, callback) => {
        const result = await sendRequest("DELETE", `fields/${fieldId}`);
        if (result.isError) {
            dispatch(displayErrorMessage(t("shared:farms.errors.delete-field"), result.data));
        } else {
            dispatch(displayInfoMessage(t("shared:farms.message-field-deleted")));
            callback && callback();
        }
        return result;
    };

    ////////////////// Get field
    const getField = async (fieldId, callback) => {
        const result = await sendRequest("GET", `fields/${fieldId}`);
        if (result.isError)
            dispatch(displayErrorMessage("Failed to get field", result.data));
        else
            callback && callback(deserializeJsonapiEntity(result.data));
        return result;
    };


    ////////////////// import status
    const importStatus = async (farmId, callback) => {
        const result = await sendRequest("GET", `fields/import/${farmId}/status`);
        if (result.isError && result.status != 418)
            dispatch(displayErrorMessage("Failed to check if import is ongoing", result.data));
        else
            callback && callback((result.status != 204 || null) && result.data);
        return result;
    };

    ////////////////// List imported fields
    const listImportFields = async (farmId, callback) => {
        const result = await sendRequest("GET", `fields/import/${farmId}`);
        if (result.isError && result.status != 418)
            dispatch(displayErrorMessage(t("shared:farms.errors.list-fields"), result.data));
        else
            callback && callback(result.data || result.error, result.status === 202);
        return result;
    };

    ////////////////// Start import fields
    const startImport = async (farmId, formData, callback) => {
        const result = await sendRequest("POST", `fields/import/${farmId}`, formData);
        if (result.isError) {
            if (result.status == 422 && result.data) {
                if (callback)
                    callback(result.data);
            } else if (result.status != 422)
                dispatch(displayErrorMessage("Failed to start import", result.data));
        } else
            callback && callback(result.data);
        return result;
    };

    ////////////////// Create field
    const createImportField = async (feature, farmId, callback) => {
        const featureCollection = {
            type: "FeatureCollection",
            features: [feature]
        }
        const result = await sendRequest("POST", `fields/import/${farmId}`, featureCollection, { "content-type": "application/geo+json" });
        if (result.isError) {
            if (result.status == 422 && result.data) {
                callback && callback(result.data);
            } else if (result.status != 422)
                dispatch(displayErrorMessage("Failed to create field", result.data));
        } else
            callback && callback(result.data);
        return result;
    };

    ////////////////// Patch field
    const patchImportField = async (fieldId, attributes, farmId, callback) => {
        const result = await sendRequest("PATCH", `fields/import/${farmId}/${fieldId}`, attributes);
        if (result.isError) {
            console.log("patch error", result);
            dispatch(displayErrorMessage(t(`shared:farms.errors.${result.data.error}`, { defaultValue: "Failed to update import field" }), result.data));
        }else
            callback && callback(result.data);
        return result;
    };

    ////////////////// Remove import field
    const removeImportField = async (fieldId, farmId, callback) => {
        const result = await sendRequest("DELETE", `fields/import/${farmId}/${fieldId}`);
        if (result.isError)
            dispatch(displayErrorMessage("Failed to remove import field", result.data));
        else
            callback && callback();
        return result;
    };

    ////////////////// Reset import
    const resetImport = async (farmId, callback) => {
        const result = await sendRequest("DELETE", `fields/import/${farmId}`);
        if (result.isError)
            dispatch(displayErrorMessage("Failed to clear import", result.data));
        else
            callback && callback();
        return result;
    };

    ////////////////// Activate fields
    const activateImportFields = async (fieldIds, farmId, callback) => {
        const result = await sendRequest("PUT", `fields/import/${farmId}`, fieldIds);
        if (result.isError)
            dispatch(displayErrorMessage("Failed to activate field(s)", result.data));
        else
            callback && callback();
        return result;
    };

    ////////////////// Rename to property
    const renameImportFields = async (propertyName, farmId, callback) => {
        const result = await sendRequest("POST", `fields/import/${farmId}/rename_by_property?property_name=${propertyName}`);
        if (result.isError)
            dispatch(displayErrorMessage("Failed to rename property", result.data));
        else
            callback && callback(result.data);
        return result;
    };

    return { listFields, createFarm, deleteFarm, patchField, deleteField, getField, importStatus, listImportFields, startImport, createImportField, patchImportField, removeImportField, resetImport, activateImportFields, renameImportFields };
};


export const useLoadFarms = (profileId, editMode, options) => {
    const dispatch = useDispatch();
    const sendRequest = useAsyncApi();
    const farmApi = useFarmApi();
    const farms = useSelector(selectFarms);
    const farm = useSelector(selectFarm);
    const importStatus = useSelector(selectImportStatus);

    useEffect(() => {
        if (!profileId || farms != null) return;

        async function asyncLoadFarms() {
            let result = await sendRequest("GET", "farms?fields[farms]=name,location,fields&sort=name");
            if (result.isError) {
                dispatch(displayErrorMessage("Failed to list farms", result.data));
                return false;
            }
            const loadedFarms = deserializeJsonapiEntities(result.data);
            dispatch(setFarms(loadedFarms));
        };

        asyncLoadFarms();
    }, [profileId, Boolean(farms)]);

    useEffect(() => {
        if (!farms || farm?.fields) return;

        async function asyncLoadFields() {
            let selectedFarmId = farm?.id;
            let fields;
            if (farms.length) {
                selectedFarmId = farms.find(f => f.id === selectedFarmId)?.id || farms[0].id;
                const result = await farmApi.listFields(selectedFarmId, options);
                if (result.isError) {
                    dispatch(displayErrorMessage("Failed to list fields", result.data));
                    return;
                }
                fields = deserializeJsonapiEntities(result.data);
                dispatch(setSelectedFarm(selectedFarmId, fields));
            } else {
                dispatch(setSelectedFarm(null, null));
            }
        };

        asyncLoadFields();
    }, [farm?.id, Boolean(farm?.fields), Boolean(farms)]);

    useEffect(() => {
        if (!farm?.fields || !editMode || importStatus) return;

        farmApi.listImportFields(farm.id, (data) => {
            dispatch(setSelectedFarmImports(data || null));
        });
    }, [Boolean(farm?.fields), importStatus, editMode]);
};
