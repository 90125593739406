import TextField from "@mui/material/TextField";

export default ({ ...props }) => {
    const inputProps = { readOnly: Boolean(props.readOnly), disableUnderline: Boolean(props.readOnly) };
    return (<TextField
        type="text"
        margin="dense"
        variant="standard"
        {...props}
        slotProps={props.readOnly && { input: inputProps } || {
            htmlInput: {
                onFocus: (e) => e.target.type = "date",
                onBlur: (e) => e.target.type = "text"
            },
            input: inputProps
        }}
    />);
};
