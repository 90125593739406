
import { LatLng } from "leaflet";
import { createSelector } from "reselect";

const selectState = (state) => state.farms;

export const selectFarms = createSelector(selectState, (state) => state.farms);

export const selectFarm = createSelector(selectState, (state) => state.selectedFarm);

export const selectFields = createSelector(selectState, (state) => (state.selectedFarm?.fields || null));

export const selectEditFields = createSelector(selectState, (state) => {
    if (!state.selectedFarm?.fields) return null;
    if (!state.selectedFarmImports?.fields?.length)
        return state.selectedFarm.fields;
    const sortedImports = state.selectedFarmImports.fields.toSorted((a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0);
    return [...state.selectedFarm.fields, ...sortedImports];
});

export const selectImportStatus = createSelector(selectState, (state) => {
    if (!state.selectedFarm?.id || state.selectedFarmImports === undefined) return null;
    if (!state.selectedFarmImports) return { status: "none" };
    let status;
    if (!state.selectedFarmImports.error && !state.selectedFarmImports.errors && !state.selectedFarmImports.fields)
        status = "in_progress";
    else if (!state.selectedFarmImports.fields)
        status = "error";
    else
        status = "review";
    return { status, ts: state.selectedFarmImports.ts };
});

export const selectMapPosition = createSelector(selectState, (state) => {
    if (!state.selectMapPosition) return null;
    return {
        zoom: state.selectMapPosition.zoom,
        center: LatLng(state.selectMapPosition.center.lat, state.selectMapPosition.center.lng)
    };
});
export const selectMapLayer = createSelector(selectState, (state) => state.mapLayer);
