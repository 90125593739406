import { Stack, ButtonBase } from "@mui/material";
import { styled } from "@mui/material/styles";
import Control from "react-leaflet-custom-control";


export const CustomMapControls = ({ children, ...props }) => {
    return (<Control {...props}>
        <Stack spacing={1}>
            {children}
        </Stack>
    </Control>);
};


export const MapButton = styled(ButtonBase)(({ disabled }) => ({
    width: "34px",
    height: "34px",
    border: "2px solid #00000033",
    borderRadius: "4px",
    padding: 0,
    backgroundColor: disabled ? "#ffffffc0" : "white",
    backgroundClip: "padding-box",
    color: disabled ? "rgba(0, 0, 0, 0.25)" : "rgba(0, 0, 0, 0.87)",
    "&:hover": disabled ? undefined : { backgroundColor: "#f4f4f4" }
}));
